import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Layout from "../layouts";
import Header from "../modules/header";
import { Helmet } from "react-helmet";
import ContentBlock from "../components/content-block";
import PropTypes from "prop-types";

const ErrorContent = ({ page }) => {
  const link = {
    page: {
      path: "/",
    },
  };
  return (
    <Layout className="error">
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Header title="404" backgroundColour={"orange"} />
      <div className="p-error-image-container container">
        {page.image?.asset?.url && (
          <div className="p-error-image-wrapper">
            <div className="e-landscape-image-container e-image-container">
              {/* Bug on safari caused Gatsby Image not to load */}
              <img
                className="e-image"
                src={page.image.asset.url}
                alt={page.image.asset.altText}
              />
            </div>
          </div>
        )}
      </div>

      <div className="p-error-body container">
        <ContentBlock
          subtitle={page.heading}
          text={page.text}
          linkText={page.linkText}
          link={link}
        />
      </div>
    </Layout>
  );
};

ErrorContent.propTypes = {
  page: PropTypes.object,
};

export default function Error(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          sanityError {
            heading
            text
            linkText
            image {
              asset {
                url
                altText
              }
            }
          }
        }
      `}
      render={data => {
        return <ErrorContent page={data.sanityError} />;
      }}
    />
  );
}
